import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {CustomerGroup, GatewayServer, GatewayServerNatConfig, GatewayServerNatConfigCheck, GatewayServerWithPassword} from '../../../models';
import {convertToFormGroup, CrudOperationWrapper, markAsTouched} from '../../../helpers/kluh';
import {FormBuilder, FormControl, FormGroup, ValidationErrors} from '@angular/forms';
import {GatewayServerDaoService} from '../gateway-server-dao.service';
import {ComponentCleaner} from '../../../component-cleaner';
import {Observable} from 'rxjs/internal/Observable';
import {invalidIpv4OrIpv6OrFqdn, invalidLastCharacterIsNumber} from '../../../utils/utils-kluh';
import {ConfirmDialogComponent} from '../../../helpers/confirm-dialog/confirm-dialog.component';
import {GatewayServerNatConfigDaoService} from '../gateway-server-nat-config/gateway-server-nat-config-dao.service';
import {CustomerGroupService} from '../../customer-group/customer-group.service';
import {ManagerUserDaoService} from '../../manager-user/manager-user-dao.service';
import {forkJoin} from 'rxjs/internal/observable/forkJoin';
import {EntityHistoryComponent} from '../../javers/entity-history/entity-history.component';
import {PermissionWrapper} from '../../../directives/if-permission.directive';
import {from} from 'rxjs';
import {concatMap, delay, mergeMap} from 'rxjs/operators';

@Component({
    selector: 'app-gateway-server-edit',
    templateUrl: './gateway-server-edit.component.html',
    styleUrls: ['./gateway-server-edit.component.scss']
})
export class GatewayServerEditComponent extends ComponentCleaner implements OnInit {
    appCanWriteList: PermissionWrapper[];
    passwordError = false;
    gatewayServer: GatewayServer;
    myForm: FormGroup;
    gatewayServerPasswordForm: FormControl = new FormControl();
    gatewayServerPasswordRepeatForm: FormControl = new FormControl();
    changePassword = false;
    checkingCommunication = false;
    canSendRequestToGatewayServer = false;
    customerGroup: CustomerGroup;
    loading = false;

    constructor(
        private dialog: MatDialog,
        private fb: FormBuilder,
        private customerGroupService: CustomerGroupService,
        private managerUserDaoService: ManagerUserDaoService,
        private gatewayServerDaoService: GatewayServerDaoService,
        private gatewayServerNatConfigDaoService: GatewayServerNatConfigDaoService,
        public dialogRef: MatDialogRef<GatewayServerEditComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        super();
        this.appCanWriteList = [
            {type: 'GatewayServer', permission: 'WRITE', parentType: 'CustomerGroup', parent: this.customerGroupService.customerGroup},
        ];

        this.gatewayServer = data.gatewayServer ?? this.gatewayServerDaoService.initGatewayServer();
        this.gatewayServer.administrationIps = this.quotesToBreakLines(this.gatewayServer.administrationIps);
        this.gatewayServer.customerGroupId = data.gatewayServer?.customerGroupId ?? data.customerGroupId;
        this.myForm = this.fb.group(
            convertToFormGroup(this.gatewayServer), {asyncValidator: this.gatewayServerDaoService.validator}
        );
        this.myForm.get('administrationIps').setValidators([invalidIpv4OrIpv6OrFqdn(), invalidLastCharacterIsNumber()]);
        this.checkIsInvalidOrNotAvailableGatewayServer();
    }

    ngOnInit(): void {
        this.addSubscription(this.myForm.valueChanges.subscribe(() => {
            this.checkIsInvalidOrNotAvailableGatewayServer();
        }));
        this.addSubscription(this.gatewayServerPasswordForm.valueChanges.subscribe(() => {
            markAsTouched(this.myForm);
            this.checkPassword();
            this.checkIsInvalidOrNotAvailableGatewayServer();
        }));
        this.addSubscription(this.gatewayServerPasswordRepeatForm.valueChanges.subscribe(() => {
            markAsTouched(this.myForm);
            this.checkPassword();
            this.checkIsInvalidOrNotAvailableGatewayServer();
        }));
        this.customerGroupService.get().subscribe(customerGroup => {
            if (customerGroup) {
                this.customerGroup = customerGroup;
            }
        });
    }


    onRemove(): void {
        const subscription = this.dialog.open(ConfirmDialogComponent, {
            disableClose: true,
            data: {
                message: '<div>Tem certeza que deseja deletar esse do R2 Security Server? ' +
                    '<br><br> Para remover digite <u>DELETE</u> no campo abaixo</div>',
                disableCancel: false,
                cancelButtonValue: 'Cancelar',
                confirmButtonValue: 'Deletar',
                icon: 'error_outline',
                confirmFieldValue: 'DELETE'
            }
        }).afterClosed().subscribe((result) => {
            if (result) {
                this.gatewayServerNatConfigDaoService.canDelete(this.gatewayServer.id).subscribe((canDelete) => {
                    if (canDelete) {
                        const gatewayServerWithPassword: GatewayServerWithPassword = this.gatewayServerDaoService.initGatewayServerWithPassword();
                        gatewayServerWithPassword.gatewayServerDTO = this.gatewayServer;
                        const password: string = this.gatewayServerPasswordForm.value;
                        if (password) {
                            gatewayServerWithPassword.password = password;
                        }
                        this.gatewayServerDaoService.asyncDelete(gatewayServerWithPassword).subscribe(() => {
                            const crudOperation: CrudOperationWrapper = {
                                data: null,
                                operation: 'DELETE'
                            };
                            this.dialogRef.close(crudOperation);
                        });
                    } else {
                        this.alertMessage('<h1>R2 Security Server não pode ser deletado</h1><div class="mb-60"><b>Não pode ser deletado</b> um R2 Security Server que está sendo usado.</div>');
                    }
                });

            }
        });
        this.addSubscription(subscription);
    }


    private alertMessage(message: string): void {
        this.addSubscription(
            this.dialog.open(ConfirmDialogComponent, {
                disableClose: true,
                data: {
                    message: message,
                    disableCancel: true,
                    confirmButtonValue: 'OK',
                    icon: 'error_outline'
                }
            }).afterClosed().subscribe(() => {
            })
        );
    }

    convertFromAliasValueToIpList(aliasValue: string): string[] {
        return aliasValue.split(' ');
    }

    ipListsAreDifferent(ips1: string[], ips2: string[]): boolean {
        if (ips1.length !== ips2.length) {
            return true;
        }
        for (let i = 0; i < ips1.length; i++) {
            if (ips1[i] !== ips2[i]) {
                return true;
            }
        }
        return false;
    }

    private showsContentFromList1ThatDoesNotExistInList2(list1: string[], list2: string[]): string[] {
        return list1.filter(obj => !list2.includes(obj));
    }

    checkGatewayCommunication(): void {
        this.checkIsInvalidOrNotAvailableGatewayServer(true);
        const gatewayServer = this.myForm.value;
        const password: string = this.gatewayServerPasswordForm.value;
        const gatewayServerWithPassword: GatewayServerWithPassword = this.gatewayServerDaoService.initGatewayServerWithPassword();
        gatewayServerWithPassword.gatewayServerDTO = gatewayServer;
        if (password) {
            gatewayServerWithPassword.password = password;
        }
        this.gatewayServerDaoService.suspendCheckCommunication(gatewayServerWithPassword).subscribe((valueWrapper) => {
            const message = '<h2 class="min-width-400 text-align-center">' + valueWrapper.value + '</h2>';
            const matDialogRef = this.dialog.open(ConfirmDialogComponent, {
                disableClose: true,
                data: {
                    message: message,
                    disableCancel: true,
                    icon: 'info_outline',
                    confirmButtonValue: 'OK'
                }
            });
            matDialogRef.afterClosed().subscribe(() => {
                this.checkIsInvalidOrNotAvailableGatewayServer(false);
            });
        });
    }

    checkIpsLoggedUserOnGateway(): void {
        forkJoin([
            this.managerUserDaoService.allLoggedUsersIps(),
            this.gatewayServerDaoService.suspendGetAlias(this.gatewayServer.id, 'R2CLOUD_IPS')
        ]).subscribe((results) => {
            let message: string;

            const ipsFromAPI = results[0].map(user => user.ip);
            const gatewayAlias = results[1];
            const ipsFromGatewayServer = this.convertFromAliasValueToIpList(gatewayAlias.value);
            const list1Diff = this.showsContentFromList1ThatDoesNotExistInList2(ipsFromAPI, ipsFromGatewayServer);
            const error = list1Diff.length > 0;
            if (error) {
                message = '<h1>IPs da API que não estão na lista de IPs do Gateway Server</h1>' + list1Diff.join('\n<br>');
            } else {
                message = '<br><div class="w-400"><h2 class="text-align-center">OK</h2></div>';
            }
            const matDialogRef = this.dialog.open(ConfirmDialogComponent, {
                disableClose: true,
                data: {
                    message: message,
                    disableCancel: true,
                    icon: 'info_outline',
                    confirmButtonValue: 'OK'
                }
            });
            matDialogRef.afterClosed().subscribe(() => {
            });
        });
    }

    checkIpsOnGateway(): void {
        this.gatewayServerDaoService.suspendGetAlias(this.gatewayServer.id, 'R2CLOUD_ADMINISTRATION_IPS')
            .subscribe((gatewayAlias) => {
                if (gatewayAlias) {
                    let result = '';
                    let error: boolean;
                    if (!gatewayAlias.value) {
                        error = true;
                    } else {
                        const ips1 = this.convertFromAliasValueToIpList(gatewayAlias.value);
                        const ips2 = this.myForm.get('administrationIps').value.trim().split(/\r?\n/);
                        error = this.ipListsAreDifferent(ips1, ips2);
                        if (error) {
                            result = '<table class="min-width-400"><tr><td>' + ips1.join('\n<br>') + '</td><td> </td><td>' + ips2.join('\n<br>') + '</td></tr></table>';
                        }
                    }
                    if (!error) {
                        result = '<br><div class="w-400"><h2 class="text-align-center">OK</h2></div>' + result;
                    } else {
                        result = '<h2 class="mt-0 text-align-center width-100-percent">ERROR - Listas de Ips diferentes</h2>' + result;
                    }
                    const message = result;
                    const matDialogRef = this.dialog.open(ConfirmDialogComponent, {
                        disableClose: true,
                        data: {
                            message: message,
                            disableCancel: true,
                            icon: 'info_outline',
                            confirmButtonValue: 'OK'
                        }
                    });
                    matDialogRef.afterClosed().subscribe(() => {
                        //
                    });
                }
            });
    }

    gatewayServerCreateAllConfigurations(): void {
        const method = this.gatewayServer.secure ? 'https' : 'http';
        this.addSubscription(this.dialog.open(ConfirmDialogComponent, {
            disableClose: true,
            data: {
                message: 'Você tem certeza que deseja <b>copiar todas as configurações</b> para esse firewall?<br><br>' +
                    '<b>' + method + '://' + this.gatewayServer.fqdn + ':' + this.gatewayServer.port + '</b><br>' + this.gatewayServer.login + '<br><br>',
                disableCancel: false,
                confirmButtonValue: 'OK',
                icon: 'error_outline'
            }
        }).afterClosed().subscribe((result) => {
            if (result) {
                this.loading = true;
                console.log('saving all settings');
                const gatewayServerNatConfigSavedList: GatewayServerNatConfig[] = [];
                const message = '<br><b>Salvando... Por favor, aguarde</b><br>Essa operação pode demorar alguns minutos, dependendo do número de servidores.<br><br>';
                const matDialogRef = this.showPopupMessage(message);
                this.gatewayServerNatConfigDaoService.findAllByGatewayServer(this.gatewayServer)
                    .subscribe((gatewayServerNatConfigList) => {
                        from(gatewayServerNatConfigList)
                            .pipe(
                                concatMap((gatewayServerNatConfig) =>
                                    this.gatewayServerNatConfigDaoService.suspendSave(gatewayServerNatConfig).pipe(delay(5000))
                                )
                            )
                            .subscribe(
                                (results) => {
                                    gatewayServerNatConfigSavedList.push(results);
                                },
                                (error) => {
                                    this.loading = false;
                                    matDialogRef.close();
                                    console.error('Error suspendCheck:', error);
                                },
                                () => {
                                    this.loading = false;
                                    console.log('gatewayServerNatConfigSavedList', gatewayServerNatConfigSavedList);
                                    matDialogRef.close();
                                    this.showPopupMessage('<br>Todas configurações salvas com sucesso.<br><br><br>');
                                }
                            );
                    });
            }
        }));


    }

    private showPopupMessage(message: string): MatDialogRef<ConfirmDialogComponent, any> {
        return this.dialog.open(ConfirmDialogComponent, {
            disableClose: true,
            data: {
                message: message,
                disableCancel: true,
                icon: 'info_outline',
                confirmButtonValue: 'OK'
            }
        });
    }

    gatewayServerCheckAllConfigurations(): void {
        this.addSubscription(this.dialog.open(ConfirmDialogComponent, {
            disableClose: true,
            data: {
                message: 'Você tem certeza que deseja <b>verificar todas as configurações</b> desse firewall?<br><br>' +
                    '<b>' + this.gatewayServer.fqdn + ':' + this.gatewayServer.port + '</b><br>' + this.gatewayServer.login + '<br><br>',
                disableCancel: false,
                confirmButtonValue: 'OK',
                icon: 'error_outline'
            }
        }).afterClosed().subscribe((result) => {
            if (result) {
                this.loading = true;
                console.log('check all');
                const gatewayServerNatConfigCheckList: GatewayServerNatConfigCheck[] = [];
                const message = 'Aguarde verificando...';
                const matDialogRef = this.dialog.open(ConfirmDialogComponent, {
                    disableClose: true,
                    data: {
                        message: message,
                        disableCancel: true,
                        icon: 'info_outline',
                        confirmButtonValue: 'OK'
                    }
                });
                this.gatewayServerNatConfigDaoService.findAllByGatewayServer(this.gatewayServer)
                    .subscribe((gatewayServerNatConfigList) => {
                        from(gatewayServerNatConfigList)
                            .pipe(
                                mergeMap((gatewayServerNatConfig) =>
                                    this.gatewayServerNatConfigDaoService.suspendCheck(gatewayServerNatConfig)
                                )
                            )
                            .subscribe(
                                (results) => {
                                    gatewayServerNatConfigCheckList.push(...results);
                                },
                                (error) => {
                                    console.error('Error suspendCheck:', error);
                                },
                                () => {
                                    this.loading = false;
                                    console.log('gatewayServerNatConfigCheckList', gatewayServerNatConfigCheckList);
                                    matDialogRef.close();
                                    let bodyMessage = '<table class="mb-60">';
                                    let error = false;
                                    gatewayServerNatConfigCheckList.forEach((configCheck) => {
                                        if (!configCheck.status) {
                                            error = true;
                                            bodyMessage += '<tr><td class="pt-14">name:</td> <td class="pt-14">' + configCheck.name + '</td></tr>';
                                            bodyMessage += '<tr><td>functionName:</td> <td>' + configCheck.functionName + '</td></tr>';
                                            bodyMessage += '<tr><td>status:</td> <td><b class="red p-5">ERROR</b></td></tr>';
                                            bodyMessage += '<tr><td>databaseValue:</td> <td>' + configCheck.databaseValue + '</td></tr>';
                                            bodyMessage += '<tr><td class="border-bottom pb-20">gatewayValue:</td> <td class="border-bottom pb-20">' + configCheck.gatewayValue + '</td></tr>';
                                        }
                                    });
                                    bodyMessage += '</table>';
                                    if (!error) {
                                        bodyMessage = '<br><div class="w-400"><h2 class="text-align-center">Todas as configurações estão corretas.</h2></div>' + bodyMessage;
                                    } else {
                                        bodyMessage = '<h2 class="mt-0 text-align-center width-100-percent">ERROR</h2>' + bodyMessage;
                                    }
                                    this.dialog.open(ConfirmDialogComponent, {
                                        disableClose: true,
                                        data: {
                                            message: bodyMessage,
                                            disableCancel: true,
                                            icon: 'info_outline',
                                            confirmButtonValue: 'OK'
                                        }
                                    });
                                }
                            );
                    });
            }
        }));
    }


    onCancel(): void {
        this.dialogRef.close({
            operation: 'CANCEL'
        } as CrudOperationWrapper);
    }

    onSubmit(): void {
        const gatewayServer: GatewayServer = this.myForm.value;
        gatewayServer.administrationIps = this.breakLinesToQuotes(gatewayServer.administrationIps);
        const password: string = this.gatewayServerPasswordForm.value;
        const gatewayServerWithPassword: GatewayServerWithPassword = this.gatewayServerDaoService.initGatewayServerWithPassword();
        gatewayServerWithPassword.gatewayServerDTO = gatewayServer;
        if (password) {
            gatewayServerWithPassword.password = password;
        }

        this.gatewayServerDaoService.suspendCheckCommunication(gatewayServerWithPassword).subscribe((valueWrapper) => {
            if (valueWrapper.value !== 'OK') {
                const message = '<h2 class="min-width-400 text-align-center">A comunicação com o servidor falhou, deseja salvar mesmo assim?</h2>';
                const matDialogRef = this.dialog.open(ConfirmDialogComponent, {
                    disableClose: false,
                    data: {
                        message: message,
                        disableCancel: false,
                        icon: 'info_outline',
                        confirmButtonValue: 'Salvar mesmo assim'
                    }
                });
                matDialogRef.afterClosed().subscribe((result) => {
                    if (result) {
                        this.save(gatewayServer, gatewayServerWithPassword, true);
                    }
                });
            } else {
                this.save(gatewayServer, gatewayServerWithPassword);
            }
        });
    }

    private save(gatewayServer: GatewayServer, gatewayServerWithPassword: GatewayServerWithPassword, withOutCheck: boolean = false): void {
        this.gatewayServerDaoService.checkIfCanCreate(gatewayServerWithPassword).subscribe((canCreate) => {
            if (!canCreate) {
                const fqdn = gatewayServerWithPassword.gatewayServerDTO.fqdn;
                const port = gatewayServerWithPassword.gatewayServerDTO.port || 10000;
                this.alertMessage('Esse IP e Porta já está em uso<br><br>' +
                    '<b>' + fqdn + ' : ' + port + '</b>');
            } else {
                let saveOrCrete$: Observable<GatewayServer>;
                if (withOutCheck) {
                    if (gatewayServer.id) {
                        saveOrCrete$ = this.gatewayServerDaoService.saveWithoutCheck(gatewayServerWithPassword);
                    } else {
                        saveOrCrete$ = this.gatewayServerDaoService.createWithoutCheck(gatewayServerWithPassword);
                    }
                } else {
                    if (gatewayServer.id) {
                        saveOrCrete$ = this.gatewayServerDaoService.saveWithCheck(gatewayServerWithPassword);
                    } else {
                        saveOrCrete$ = this.gatewayServerDaoService.createWithCheck(gatewayServerWithPassword);
                    }
                }
                saveOrCrete$.subscribe(result => {
                    this.dialogRef.close({
                        operation: 'SAVE',
                        data: result
                    } as CrudOperationWrapper);
                });
            }
        });
    }

    private checkPassword(): void {
        const password = this.gatewayServerPasswordForm.value;
        const passwordRepeat = this.gatewayServerPasswordRepeatForm.value;
        if (password !== passwordRepeat) {
            const errors: ValidationErrors = {};
            this.gatewayServerPasswordRepeatForm.setErrors(errors);
            this.passwordError = true;
        } else {
            this.gatewayServerPasswordRepeatForm.setErrors(null);
            this.passwordError = false;
        }
    }

    isValidPassword(): boolean {
        return !(this.passwordError || this.gatewayServerPasswordForm.value?.length < 1 || this.gatewayServerPasswordRepeatForm.value?.length < 1);
    }

    setChangePassword(): void {
        this.changePassword = true;
    }

    getAmountOfIps(): number {
        let administrationIps = '';
        if (this.myForm?.get('administrationIps')) {
            administrationIps = this.myForm.get('administrationIps').value;
        }
        const ips = administrationIps?.match(/\n/g);
        return ips ? (ips.length + 1) : 0;
    }

    quotesToBreakLines(inputString: string): string {
        return inputString?.replace(/,/g, '\n');
    }

    breakLinesToQuotes(inputString: string): string {
        return inputString?.replace(/\n/g, ',').replace(/\s+/g, '').replace(/^|$/g, '');
    }


    checkIsInvalidOrNotAvailableGatewayServer(checkingCommunication?: boolean): void {
        const {fqdn, login, id} = this.myForm.value as GatewayServer;
        if (checkingCommunication !== undefined) {
            this.checkingCommunication = checkingCommunication;
        }
        if (this.checkingCommunication || !fqdn || !login) {
            this.canSendRequestToGatewayServer = false;
            return;
        }
        this.canSendRequestToGatewayServer = this.isValidPassword();
    }

    hasChangedFqdnOrPort(): boolean {
        const gatewayServerFromForm = this.myForm.value as GatewayServer;
        return !(this.gatewayServer.fqdn === gatewayServerFromForm.fqdn && this.gatewayServer.port === gatewayServerFromForm.port);
    }


    onJaversHistory(): void {
        EntityHistoryComponent.openHistory(this.gatewayServer.id, this.gatewayServerDaoService, this.dialog);
    }

    onJaversAllHistory(): void {
        EntityHistoryComponent.openAllHistory(this.gatewayServer.id, this.gatewayServerDaoService, this.dialog);
    }

}
